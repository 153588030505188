import { WebContext } from "../../contexts/web/web-context-provider";
import { Container } from "react-bootstrap";
import React, { useContext, useMemo, useState, useEffect } from "react";
import { colors } from "../../assets/constants/colors";
import "../../App.css";
import { Row } from "react-bootstrap";
import { Alert } from "../../components/alert/alert";
import { RiSignalTowerLine } from "react-icons/ri";
import { IoWifiSharp } from "react-icons/io5";
import { IoBatteryCharging } from "react-icons/io5";
import { PhoneIcons } from "../../components/phone-icons/phone-icons";
import { useOnPressAppIcons } from "../../scripts/use-on-press-app-icons/use-on-press-app-icons";

/**
 * Home Screen
 */
export const Home = () => {
  const { onPressOpenLinkInNewTab, windowWidth, windowHeight } =
    useContext(WebContext);

  const { onPressAppIcons, isAlertOpen, toggleIsAlertOpen, alertData } =
    useOnPressAppIcons();

  // const homeData = useMemo(() => {
  //   return {
  //     mobileApps: [
  //       {
  //         name: "Resume",
  //         onPress: () =>
  //           onPressOpenLinkInNewTab({
  //             link: "https://ryan.zernach.com/resume/",
  //           }),
  //         image: require("../../assets/images/new-home/resume_icon.png"),
  //       },
  //       {
  //         name: "Github",
  //         onPress: () =>
  //           onPressOpenLinkInNewTab({ link: "https://github.com/zernach" }),
  //         image: require("../../assets/images/social/github_icon.png"),
  //       },
  //       {
  //         name: "Instagram",
  //         onPress: () =>
  //           onPressOpenLinkInNewTab({ link: "https://instagram.com/zernach" }),
  //         image: require("../../assets/images/social/instagram_color.png"),
  //       },
  //       {
  //         name: "Facebook",
  //         onPress: () =>
  //           onPressOpenLinkInNewTab({ link: "https://facebook.com/zernach/" }),
  //         image: require("../../assets/images/social/facebook_icon.png"),
  //       },
  //       {
  //         name: "YouTube",
  //         onPress: () =>
  //           onPressOpenLinkInNewTab({
  //             link: "https://www.youtube.com/channel/UCTcyHgRop949pvRCqdwnQXw/",
  //           }),
  //         image: require("../../assets/images/social/youtube_icon.png"),
  //       },
  //     ],
  //   };
  // }, [onPressOpenLinkInNewTab]);

  const lowerIcons = useMemo(() => {
    return [
      {
        name: "Portfolio",
        image: require("../../assets/images/new-home/app_folder_dark_transparent_icons.png"),
        onPress: () =>
          onPressOpenLinkInNewTab({
            link: "https://ryan.zernach.com/portfolio",
          }),
      },
      {
        name: "LinkedIn",
        onPress: () =>
          onPressOpenLinkInNewTab({ link: "https://linkedin.com/in/zernach" }),
        image: require("../../assets/images/social/linkedin_white.png"),
      },
      {
        name: "Email",
        onPress: () => onPressAppIcons({ type: "email" }),
        image: require("../../assets/images/new-home/gmail.png"),
      },
      {
        name: "WhatsApp",
        onPress: () => onPressAppIcons({ type: "messages" }),
        image: require("../../assets/images/new-home/whatsapp.png"),
      },
    ];
  }, [onPressAppIcons, onPressOpenLinkInNewTab]);

  const fullScreenStyle = {
    height: windowHeight,
    width: windowWidth,
  };

  const iconContainerStyle = {
    position: "fixed",
    top: "10px", // Adjust the top positioning as needed
    right: "30px", // Adjust the right positioning as needed
    display: "flex",
    gap: "5px",
    color: "white",
  };

  const iconStyle = {
    fontSize: "25px", // Adjust the icon size as needed
  };

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = currentTime.toLocaleTimeString(undefined, {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  });

  return (
    <div style={{ overflowY: "hidden" }}>
      <div style={fullScreenStyle} className="homeScreen">
        <Container className="contentContainer">
          {/* 👇 CURRENT TIME */}
          <div
            style={{
              position: "fixed",
              top: "10px",
              left: "30px",
              display: "flex",
              gap: "5px",
            }}
          >
            <p
              style={{
                color: colors.white,
                fontSize: "20px",
                marginTop: "5px",
              }}
            >
              {formattedTime}
            </p>
          </div>
          {/* 👇 SIGNAL, WIFI, BATTERY ICONS */}
          <div style={iconContainerStyle}>
            <div style={iconStyle}>
              <RiSignalTowerLine />
            </div>
            <div style={iconStyle}>
              <IoWifiSharp />
            </div>
            <div
              style={{
                fontSize: "28px", // Adjust the icon size as needed
                marginTop: "-3px",
              }}
            >
              <IoBatteryCharging />
            </div>
          </div>
          {/* 👇 ALERT POPUP OVERLAY */}
          {isAlertOpen && (
            <Alert
              title={alertData.title}
              message={alertData.message}
              onClose={toggleIsAlertOpen}
            />
          )}
          {/* 👇 APP ICONS */}
          <Row style={{ paddingTop: "82px" }}>
            {/* <PhoneIcons
              icons={homeData.mobileApps}
              onPressStyle={{ marginTop: "2.5vw" }}
            /> */}
            <h1>{"Archlife LLC"}</h1>
            <h2>{"AI Infrastructure & Mobile App Development"}</h2>
            <h4>{'100% Remote, Miami Beach, Florida, USA 🇺🇸'}</h4>
          </Row>
          {/* 👇 LOWER APP ICONS */}
          <div
            className="lowerIconsContainer"
            style={{
              position: "fixed",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              gap: "5px",
              backgroundColor: `${colors.archDark}99`,
              width: "96%",
              maxWidth: "1260px",
              borderRadius: "15px",
            }}
          >
            <Row
              style={{
                paddingTop: "20px",
                justifyContent: "center",
              }}
            >
              <PhoneIcons icons={lowerIcons} />
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};
